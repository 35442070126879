<template>
  <v-select
    :value="time"
    @change="handleChangeTime"
    :label="label"
    :items="items"
    :item-text="String(timeFormat)"
    item-value="id"
    return-object
    hide-details
    dense
  ></v-select>
</template>

<script>
import moment from "moment";

export default {
  name: "VTimePicker",
  props: {
    type: String,
    value: String,
    label: String,
    startTime: {
      type: String,
      default: () => null,
    },
    endTime: {
      type: String,
      default: () => null,
    },
    timeFormat: {
      type: Number,
      default: 24,
    },
    minuteStep: {
      type: Number,
      default: 15,
    },
  },

  watch: {
    startTime(startTime) {
      if (this.type === "end" && startTime >= this.time.value) {
        const nextTime = moment(startTime, "HH:mm").add(this.minuteStep, "minutes").format("HH:mm");

        this.handleChangeTime({
          id: this.times.find((time) => nextTime === time.value).id,
          12: moment(nextTime, "HH:mm").format("HH:mm A"),
          24: nextTime,
          value: nextTime,
        });
      }
    },
  },

  data() {
    return {
      times: [],
    };
  },

  created() {
    const times = this.timelineLabels("00:00", this.minuteStep, "minutes");
    switch (this.type) {
      case "start":
        this.times = times.slice(0, -1);
        break;
      case "end":
        times[times.length - 1] = {
          ...times[times.length - 1],
          24: "24:00",
          value: "24:00",
        };
        this.times = times.slice(1);
        break;
      default:
        return times;
    }
  },

  computed: {
    time() {
      return {
        id: this.times.find((time) => this.value === time.value).id,
        12: moment(this.value, "HH:mm").format("HH:mm A"),
        24: this.value,
        value: this.value,
      };
    },
    items() {
      switch (this.type) {
        case "end":
          return this.times.map((time, idx, times) => ({
            ...time,
            disabled: time.value <= this.startTime && times.length - 1 > idx,
          }));
        default:
          return this.times;
      }
    },
  },

  methods: {
    timelineLabels(desiredStartTime, interval, period) {
      const periodsInADay = moment.duration(1, "day").as(period);

      const timeLabels = [];
      const startTimeMoment = moment(desiredStartTime, "hh:mm");
      for (let i = 0; i <= periodsInADay; i += interval) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        timeLabels.push({
          id: i,
          12: startTimeMoment.format("hh:mm A"),
          24: startTimeMoment.format("HH:mm"),
          value: startTimeMoment.format("HH:mm"),
        });
      }

      return timeLabels;
    },
    handleChangeTime({ value }) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.rs-time-picker {
  display: flex;
  align-items: center;
}
.rs-time-picker-select {
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #b5b5b5;
}
.rs-time-picker-separator {
  display: block;
  margin: 0 5px;
  font-weight: 600;
}
</style>