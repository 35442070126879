












































































































import { Component, Prop, Watch, Ref, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";
import { validationMixin } from "vuelidate";

import VPhotoIterator from "./VPhotoIterator.vue";
import VPhotoUploaderNotice from "./VPhotoUploaderNotice.vue";
import VImageCropper from "../media/VImageCropper.vue";

import imagesService from "../../services/images.service";
import * as Validator from "../../services/validators.service";

import { IMAGE_RESTRICTIONS } from "../../config";
import { MediaModel as ImageModel, UserModel } from "@/@types/model";
import { ImageTypes } from "@/@types/common";

const { mimetype, fileSize } = Validator;

type EditButtonSizes = "xs" | "sm" | "md" | "lg" | "xl";
type ImageForCropper = { src: string | null; type: string | null };

@Component({
  name: "VPhotoUploader",
  mixins: [validationMixin],
  components: { VPhotoIterator, VPhotoUploaderNotice },
})
export default class VPhotoUploader extends Vue {
  @Prop({ type: Object, default: null }) readonly photo!: ImageModel | null;
  @Prop({ type: String, default: "lg" }) readonly size!: string;
  @Prop({ type: String, default: "sm" }) readonly fallbackSize!: string;
  @Prop({ type: String, default: "" }) readonly partPath!: string;
  @Prop({ type: [Number, String], default: 1 }) readonly aspectRatio!: number | string;
  @Prop(String) readonly maxWidth!: string;
  @Prop(String) readonly maxHeight!: string;
  @Prop({ type: String, default: "IMAGE" }) readonly type!: ImageTypes | "ALL";
  @Prop({ type: String, default: "md" }) readonly editButtonSize!: EditButtonSizes;
  @Prop(String) readonly editButtonLabel!: string;
  @Prop(String) readonly rounded!: string;
  @Prop(Boolean) readonly thumbnail!: boolean;

  @Get("auth/user") readonly user!: UserModel;

  @Ref() refFile!: HTMLInputElement;

  showMenu: boolean = false;
  selectPhoto: ImageModel | null = null;
  localPhoto: ImageModel | null = this.photo;
  imageForCopper: ImageForCropper = { src: null, type: null };
  file: File | null = null;
  isBusy: boolean = false;
  dialogSelectPhoto: boolean = false;
  dialogAgree: boolean = false;

  validations() {
    return {
      file: {
        mimetype: mimetype(["image/jpg", "image/jpeg"], [".jpg", ".jpeg"]),
        fileSize: fileSize(5000),
      },
    };
  }

  @Watch("photo")
  onPhotoChanged(photo: ImageModel) {
    this.localPhoto = photo;
  }

  get localEditButtonSize() {
    if (this.editButtonSize === "xs") return "x-small";
    if (this.editButtonSize === "sm") return "small";
    if (this.editButtonSize === "md") return "medium";
    if (this.editButtonSize === "lg") return "large";
    if (this.editButtonSize === "xl") return "x-large";
    return "medium";
  }
  get localEditButtonStyle() {
    if (this.editButtonSize === "xs") {
      return { minWidth: "40px", height: "40px", bottom: "-10px", right: "-10px" };
    }
    if (this.editButtonSize === "sm") {
      return { minWidth: "50px", height: "50px", bottom: "-12px", right: "-12px" };
    }
    return { minWidth: "64px", height: "64px", bottom: "-12px", right: "-12px" };
  }
  get localEditButtonIconSize() {
    if (this.editButtonSize === "xs") return 26;
    return 32;
  }
  get fileErrors() {
    const errors: string[] = [];
    if (!this.$v.file.$dirty) return errors;
    !this.$v.file.mimetype &&
      errors.push(
        `The must be a file of type: ${this.$v.file.$params.mimetype.value.labels.join(", ")}.`,
      );
    !this.$v.file.fileSize &&
      errors.push(`The must be ${this.$v.file.$params.fileSize.value} kilobytes.`);
    return errors;
  }

  async handleUploadPhoto(e: Event) {
    this.$v.file.$reset();

    let { files } = e.target as HTMLInputElement;

    if (!files || files.length === 0) {
      this.file = null;
      return;
    }

    const [file] = files;
    this.refFile.value = "";

    this.file = file;
    this.$v.file.$touch();

    if (this.$v.file.$invalid) {
      const [firstError] = this.fileErrors;
      this.$dialog.notify.warning(firstError);
      return;
    }

    const imageType = this.type !== "ALL" ? this.type : "IMAGE";

    // Start Image Validation
    const imageErrors = await Validator.image(this.file, imageType);

    if (imageErrors.length) {
      const [firstError] = imageErrors;
      this.$dialog.notify.warning(firstError);
      return;
    }
    // End Image Validation

    const sizeRestrictions = IMAGE_RESTRICTIONS[imageType];
    const aspectRatio = IMAGE_RESTRICTIONS[imageType].aspectRatio || 1;

    try {
      const blob = URL.createObjectURL(this.file);
      this.imageForCopper.src = blob;
      this.imageForCopper.type = this.file.type;

      const croppedImage = await this.$dialog.showAndWait(VImageCropper, {
        image: this.imageForCopper,
        sizeRestrictions,
        aspectRatio,
        showClose: false,
      });

      if (!croppedImage) return;

      this.file = new File([croppedImage], this.file.name, {
        type: this.file.type,
        lastModified: this.file.lastModified,
      });

      this.isBusy = true;
      const photo = await imagesService.uploadImage(this.file, { imageType });
      this.localPhoto = photo;
      this.$emit("upload", this.localPhoto);
    } catch (e) {
      console.log(e);
    } finally {
      this.isBusy = false;
    }
  }

  handleSelectPhoto(photo: ImageModel) {
    this.selectPhoto = photo;
  }

  handleCloseSelectPhotoDialog() {
    this.dialogSelectPhoto = false;
    this.selectPhoto = null;
  }

  handleSaveSelectPhoto() {
    this.localPhoto = this.selectPhoto;
    this.selectPhoto = null;

    this.dialogSelectPhoto = false;

    this.$emit("upload", this.localPhoto);
  }

  handleClearPhoto() {
    this.$v.file.$reset();

    this.localPhoto = null;
    this.file = null;

    this.$emit("clear");
  }

  destroyed() {
    if (this.imageForCopper.src) {
      URL.revokeObjectURL(this.imageForCopper.src);

      this.imageForCopper.src = null;
      this.imageForCopper.type = null;
    }
  }
}
